import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SignupFooter from "./signupFooter"
// import HTML from "../html/footer.html";

// var __html = require('../html/footer.html');
// var template = { __html: __html };

const Footer = ({ data }) => (
  <footer>
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <h3>Quick Links</h3>
        <ul className="list-unstyled">
          <li><Link to="/">Home</Link></li>
          <li><a href={`${process.env.GATSBY_DRUPAL_BASE_URL}/getstarted`}>Get Started</a></li>
          <li><Link to="/faq">FAQ</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><a href={`${process.env.GATSBY_DRUPAL_BASE_URL}/user/login`}>Sign In</a></li>
          <li><Link to="/terms-of-use/">Terms of Use</Link></li>
          <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
        </ul>
      </div>

      <div className="col-md-6">
       <SignupFooter />
      </div>
    </div>

    <div className="row">
      <div className="col-md-12 text-center mt-5">
        <p><small>© 2019 - 2020 Clearinghouse Navigator.</small></p>
      </div>
    </div>
  </div>
</footer> 
)

Footer.propTypes = {
  data: PropTypes.string,
}

Footer.defaultProps = {
  data: ``,
}

export default Footer
