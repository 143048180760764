import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "./img/clearinghouse_navigator_logo.png" 

const activeStyles = {
  color: '#001647'
}

const Header = ({ siteTitle }) => (
  <header className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 shadow-sm">
        <div className="my-0 mr-md-auto font-weight-normal">
          <Link to="/"  alt="Clearinghouse Navigator - Federal Motor Carrier Safety Administration Training" ><img alt="Logo" src={logo} /></Link>
        </div>
        <nav className="my-2 my-md-0 mr-md-3">
          <a href={`${process.env.GATSBY_DRUPAL_BASE_URL}/getstarted`}>Get Started</a>
          <Link to="/faq">FAQ</Link>
          <Link to="/about">About</Link>
          <Link to="/blog" activeClassName="active" activeStyle={activeStyles}>Blog</Link>
          <a href={`${process.env.GATSBY_DRUPAL_BASE_URL}/user/login`}>Sign In</a>
        </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
