import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

const SignupFooter = () => (
  <>
    <Helmet>
      <link href="https://cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet"/>      
    </Helmet> 

    <div id="mc_embed_signup">

      <form action="https://cahillswift.us20.list-manage.com/subscribe/post?u=cfd90fd29e2652887cad73809&amp;id=b1f1344462" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate >
       <div id="mc_embed_signup_scroll">
        <h3>Sign up for News &amp; Updates!</h3>

          <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>

          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
            <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>

          <div className="mc-field-group">
            <label htmlFor="mce-FNAME">First Name </label>
            <input type="text" defaultValue="" name="FNAME" className="" id="mce-FNAME"/>
          </div>

          <div className="mc-field-group">
            <label htmlFor="mce-LNAME">Last Name </label>
            <input type="text" defaultValue="" name="LNAME" className="" id="mce-LNAME"/>
          </div>

          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: `none` }}></div>
            <div className="response" id="mce-success-response" style={{ display: `none` }}></div>
          </div>

          <div style={{ position: `absolute`,  left: '-5000px' }}  aria-hidden="true">
            <input type="text" name="b_cfd90fd29e2652887cad73809_b1f1344462" tabIndex="-1" value="" readOnly/>
          </div>

          <div className="clear">
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn  btn-outline-primary"/>
          </div>
                
        </div>  
      </form>
    </div> 
  </>
)

SignupFooter.propTypes = {
  siteTitle: PropTypes.string,
}

SignupFooter.defaultProps = {
  siteTitle: ``,
}

export default SignupFooter
